import React, { useState, useEffect } from "react"
import { Carousel } from "react-bootstrap"
import { Link } from "gatsby"

import Image from "../../image"
import { LandingCarouselStyles } from "./style"

import { getBanners } from "../../../services/banner"

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function getUrl(url) {
  if (url?.includes("category_id")) {
    const temp = url.split("=")
    return "/search?cat=" + temp[temp.length - 1]
  } else {
    if (url !== "") {
      return url
    } else {
      return "#"
    }
  }
}

const desktopBanners = [
  {
    image: "flyer dan thumbnail baru-17.jpg",
    url: "/personality-test/",
  },
  {
    image: "flyer dan thumbnail baru-15.jpg",
    url: "category_id=2",
  },
  {
    image: "flyer dan thumbnail baru-14.jpg",
    url: "category_id=2",
  },
]

const renderBanners = (banners, banner1, banner2) => (
  <div className="all-banners">
    <div className="banners-left">
      <Carousel>
        {banners.map(({ image, url }, index) => (
          <Carousel.Item key={index}>
            <Link to={getUrl(url)} className="img-wrapper">
              {!image || image?.startsWith("http") ? (
                <img src={image} alt={`Slide ${index}`} />
              ) : (
                <Image imgName={image} alt={`Slide ${index}`} />
              )}
            </Link>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
    <div className="banners-right">
      <Link to={getUrl(banner1.url)} className="img-wrapper">
        {!banner1.image || banner1.image?.startsWith("http") ? (
          <img src={banner1.image} alt="Banner 1" />
        ) : (
          <Image imgName={banner1.image} alt="Banner 1" />
        )}
      </Link>
      <Link to={getUrl(banner2.url)} className="img-wrapper">
        {!banner2.image || banner2.image?.startsWith("http") ? (
          <img src={banner2.image} alt="Banner 2" />
        ) : (
          <Image imgName={banner2.image} alt="Banner 2" />
        )}
      </Link>
    </div>
  </div>
)

export default function LandingCarousel() {
  const [banners, setBanners] = useState({})
  const [banner1, setBanner1] = useState({})
  const [banner2, setBanner2] = useState({})
  const [banner3, setBanner3] = useState({})
  const [banner4, setBanner4] = useState({})

  useEffect(() => {
    getBanners().then(resp => {
      const data = resp.data.results
      if (data?.length > 0) {
        setBanners(data)

        let idBanner1 = null
        let idBanner2 = null

        idBanner1 = getRandomInt(0, data.length - 1)
        idBanner2 = 0
        if (data.length > 1) {
          while (idBanner1 === idBanner2) {
            idBanner2 = getRandomInt(0, data.length - 1)
          }
        }

        setBanner1(data[idBanner1])
        setBanner2(data[idBanner2])

        let idBanner3 = null
        let idBanner4 = null

        idBanner3 = getRandomInt(0, desktopBanners.length - 1)
        idBanner4 = 0
        if (desktopBanners.length > 1) {
          while (idBanner3 === idBanner4) {
            idBanner4 = getRandomInt(0, desktopBanners.length - 1)
          }
        }

        setBanner3(desktopBanners[idBanner3])
        setBanner4(desktopBanners[idBanner4])
      }
    })
  }, [])

  return (
    <LandingCarouselStyles id="banners">
      {banners?.length > 0 && banner1 && banner2 ? (
        <div>
          <div className="desktop-only">
            {renderBanners(desktopBanners, banner3, banner4)}
          </div>
          <div className="mobile-only">
            {renderBanners(banners, banner1, banner2)}
          </div>
        </div>
      ) : (
        <></>
      )}
    </LandingCarouselStyles>
  )
}
