import React from "react"
import EventCard from "./EventCard"
import EventSectionStyles from "./style"
import Slider from "react-slick"
import Image from "../../image"

const events = [
  {
    title: "Create Your Creativity",
    category: "Lomba",
    partner: "Rifqi Art Studio",
    date: "29 Maret 2022 - 5 Mei 2022",
    slug: "create-your-creativity",
    price: "Free",
    thumb:
      "https://i.postimg.cc/nVYQCQXK/POSTER-LOMBA-RIFQIARTSTUDIO-2022-01.jpg",
    description: {
      __html: `<p><img style="max-width: 100%;" src="https://i.postimg.cc/nVYQCQXK/POSTER-LOMBA-RIFQIARTSTUDIO-2022-01.jpg" alt="Poster dari Lomba Create Your Creativity" /></p>`,
    },
    karyaPemenang: true,
    url: "https://linktr.ee/purwalenta",
  },
  {
    title: "Meaningful Language Learning in Distance Learning",
    category: "Webinar",
    partner: "UPH Teachers College",
    location: "Zoom Meeting",
    date: ["17 Juli 2020"],
    price: "Free",
    slug: "meaningful-language-learning",
    thumb:
      "https://i.postimg.cc/qqhBCqHh/Whats-App-Image-2021-12-23-at-15-05-08.jpg",
    description: `<img src="https://i.postimg.cc/qqhBCqHh/Whats-App-Image-2021-12-23-at-15-05-08.jpg" alt="Poster dari Webinar Meaningful Language Learning in Distance Learning" />`,
  },
  {
    title: "The Future of Work: Practitioner's Perspective",
    category: "Webinar",
    partner: null,
    location: "Zoom Meeting",
    date: "19 Desember 2020",
    price: "Free",
    slug: "the-future-of-work",
    thumb: "https://img.youtube.com/vi/W0WRJ7KL8K8/maxresdefault.jpg",
    url: "https://youtu.be/W0WRJ7KL8K8",
  },
]

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: (
    <div>
      <Image imgName="right-chevron.png" alt="next" />
    </div>
  ),
  prevArrow: (
    <div>
      <Image imgName="left-chevron.png" alt="next" />
    </div>
  ),
  responsive: [
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const EventSection = () => {
  return (
    <EventSectionStyles>
      <div className="judul">
        <h3>Daftar Acara</h3>
      </div>
      <div className="under-line"></div>
      <p className="subtitle">
        Lakukan aktivitas terasik bersama tutor eksklusif.
      </p>
      <div className="event-list">
        <Slider {...settings}>
          {events.map((event, key) => {
            return <EventCard key={key} event={event} />
          })}
        </Slider>
      </div>
    </EventSectionStyles>
  )
}

export default EventSection
