import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LandingPage from "../components/LandingPage"

const IndexPage = () => {
  return (
    <Layout topTransparent={true}>
      <SEO title="Kembangkan talentamu bersama mentor favorit" />
      <LandingPage />
    </Layout>
  )
}

export default IndexPage
