import React from "react"

import Image from "../../image"

import { PartnerListStyles } from "./style"

const partners = [
  {
    imgName: "usu.png",
    name: "Fasilkom USU",
    url: "https://www.youtube.com/watch?v=W0WRJ7KL8K8&t=1s",
  },
  {
    imgName: "uph.png",
    name: "UPH Teachers College",
    url: "https://postimg.cc/kRCP0n4q",
  },
  {
    imgName: "hijo.png",
    name: "HiJo",
    url: "https://youtu.be/FeJrzm8DyJM",
  },
  {
    imgName: "anjas.jpg",
    name: "Anjas Maradita",
    url: "https://www.instagram.com/anjas_maradita/",
  },
  {
    imgName: "rifqi.jpg",
    name: "Rifqi Art Studio",
    url: "https://www.instagram.com/rifqiartstudio/",
  },
]

export default function PartnerList() {
  return (
    <PartnerListStyles>
      <h3>Rekan Kolaborasi</h3>
      <div className="under-line"></div>
      <p className="subtitle">
        Menciptakan semangat kolaborasi untuk komunitas yang berarti.
      </p>
      <div className="rekan-list">
        {partners.map(({ imgName, name, url }, index) => (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
            style={{ textDecoration: "none", color: "black" }}
          >
            <div className="one-rekan" key={index}>
              <div className="one-rekan-img">
                <Image imgName={imgName} alt={name} />
              </div>
              <div style={{ marginTop: "5px" }}>{name}</div>
            </div>
          </a>
        ))}
      </div>
    </PartnerListStyles>
  )
}
