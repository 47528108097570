import styled from "styled-components"

export const LandingCTAStyles = styled.div`
  text-align: center;
  background: #f7f7f7;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 22px;
  margin-bottom: 40px;

  h3,
  h4 {
    font-weight: bold;
  }

  .gray {
    color: #85807f;
    font-weight: normal;
  }

  button {
    width: 150px;
    height: 54px;

    border-radius: 8px;

    font-size: 1.25em;
    font-weight: bold;

    margin-top: 8px;
  }
`
