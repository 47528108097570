import React from "react"
import styled from "styled-components"
import StarRatings from "react-star-ratings"

const StyledRating = styled.div`
  display: flex;
  gap: 3px;
  align-items: stretch;

  & > div:first-child {
    font-size: 0.9em;
    padding: 1px;
    padding-top: 2px;
    z-index: 1;
    color: black;
  }

  &.light > div:first-child {
    color: white;
  }

  & > .rating-stars {
    display: flex;
    overflow: hidden;
    margin-top: -2px;
  }
`

const Rating = ({ rate, ...props }) => {
  const numRate = parseFloat(rate)

  return rate ? (
    <StyledRating {...props}>
      <div>{numRate.toFixed(1)}</div>
      <div className="rating-stars desktop-only">
        <StarRatings
          rating={numRate}
          starRatedColor="#ff9529"
          starEmptyColor="#d1d5db"
          starDimension="1rem"
          starSpacing="0px"
        />
      </div>
    </StyledRating>
  ) : (
    <StyledRating {...props} />
  )
}

export default Rating
