import styled from "styled-components"

export const WhySectionStyles = styled.div`
  /* top | right | bottom | left */
  display: flex;
  padding-bottom: 6rem;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 991.98px) {
    background: #f3f4f6;
  }

  .why-left {
    width: 50%;
    background: #f3f4f6;
    margin: 45px 0 45px 0;
    display: flex;
    align-items: center;

    .why-left-block {
      margin: 15px;
      margin: 0 auto 0 auto;
      max-width: 520px;
      margin-left: 20px;
    }

    h2 {
      font-weight: bold;

      @media (min-width: 991.98px) {
        text-align: right;
        padding-right: 1rem;
      }
    }

    p {
      line-height: 1.75;
      max-width: 450px;
    }
  }

  .why-right {
    width: 50%;
    border-radius: 1rem;
    overflow: hidden;
    margin-right: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
      0 8px 10px -6px rgb(0 0 0 / 0.1);

    video {
      width: 100%;
    }

    @media (max-width: 991.98px) {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: 991.98px) {
    flex-wrap: wrap;

    .why-left {
      width: 100%;
      padding: 20px;
      padding-top: 35px;
      margin: 0 0 0 0;

      .why-left-block {
        margin: 0;
      }
    }

    .why-right {
      width: 100%;
    }
  }
`
