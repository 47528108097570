import styled from "styled-components"

const EventCardStyles = styled.div`
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 640px;
  position: relative;
  margin: auto;

  @media (min-width: 640px) {
    flex-direction: row;
  }

  .thumbnail {
    position: relative;
    width: auto;
    height: 10rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    flex-shrink: 0;

    @media (min-width: 640px) {
      border-radius: 0.5rem 0rem 0 0.5rem;
      width: 10rem;
    }
  }

  .body {
    width: auto;
    height: auto;
    padding-top: 0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 2rem;
    color: black;

    @media (max-width: 640px) {
      padding-bottom: 4.5rem;
    }

    .category {
      font-size: 0.75rem;
      line-height: 1rem;
      color: #efa51d;
      font-weight: 800;
    }

    .title {
      margin-top: 0.25rem;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 600;
      height: 2.75rem;
      @media (max-width: 640px) {
        height: auto;
      }
    }

    .tanggal {
      margin-top: 0.5rem;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .harga {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .detail {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 1rem;
      text-align: right;
      background-color: #efa51d;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      font-size: 1rem;
      color: white;

      @media (min-width: 640px) {
        display: none;
      }
    }

    .detail::after {
      background-color: #efa51d;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      transition: transform 250ms;
      border-radius: 4px;
    }
    .detail:hover::after {
      transform: scale(1.1);
    }
  }

  :hover {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  :hover .detail {
    display: block;
  }
`

export default EventCardStyles
