import { Link } from "gatsby"
import React from "react"

import Slider from "react-slick"
import Image from "../../image"
import Rating from "../../Reusable/Rating"
import { TutorCarouselStyles } from "./style"

const tutorList = [
  {
    image: "tutor-001.png",
    rating: "5.0",
    tutor: "Lukas Gunawan",
    name: "lukas-gunawan",
    id: -1,
  },
  {
    image: "tutor-002.png",
    rating: "5.0",
    tutor: "Riyandi Kusuma",
    name: "riyandi-kusuma",
    id: 53,
  },
  {
    image: "tutor-003.png",
    rating: "5.0",
    tutor: "Ober Fernando",
    name: "ober-fernando",
    id: 64,
  },
  {
    image: "tutor-004.png",
    rating: "5.0",
    tutor: "Rio Purba",
    name: "rio-purba",
    id: 52,
  },
  {
    image: "tutor-005.png",
    rating: "5.0",
    tutor: "Mindplace Studio",
    name: "mindplace-studio",
    id: -1,
  },
  {
    image: "tutor-006.png",
    rating: "5.0",
    tutor: "Anjas Rizky Maradita",
    name: "anjas-rizky-maradita",
    id: 50,
  },
]

export default function TutorCarousel() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: (
      <div>
        <Image imgName="right-chevron.png" alt="next" />
      </div>
    ),
    prevArrow: (
      <div>
        <Image imgName="left-chevron.png" alt="next" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <TutorCarouselStyles>
      <h3 style={{ textAlign: "center" }}>Pengajar Pilihan</h3>
      <div className="under-line"></div>
      <p className="subtitle">
        Jadilah pembelajar percaya diri bersama pengajar yang telah teruji.
      </p>
      <div className="tutor-list">
        <Slider {...settings}>
          {tutorList.map(({ image, rating, tutor, name, id }, index) => (
            <Link to={`/${name}`} key={index}>
              <div className="one-tutor">
                <div className="tutor-img">
                  <Image imgName={image} alt={`Tutor ${index}`} />
                </div>
                <div className="tutor-rating">
                  <Rating rate={rating} />
                </div>
                <div style={{ paddingTop: "5px", color: "black" }}>{tutor}</div>
              </div>
            </Link>
          ))}
        </Slider>
      </div>
    </TutorCarouselStyles>
  )
}
