import styled from "styled-components"

export const TopLandingStyles = styled.div`
  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -100;
  }
  .video-container > video {
    display: block;
    position: relative;
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  @media screen and (max-aspect-ratio: 1920/1080) {
    .video-container > video {
      height: 100%;
    }
  }
  @media screen and (min-aspect-ratio: 1920/1080) {
    .video-container > video {
      width: 100%;
    }
  }
  .top-landing-wrapper {
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    text-shadow: 0 0 2em black;
  }
  .TopLandingContainer {
    width: 100%;
    padding: 12px;
  }
  .JudulTalenta {
    color: white;
    font-weight: 1000;
    font-size: 54px;
  }
  .SubJudulTop {
    color: white;
    font-size: 22px;
    letter-spacing: 2px;
  }
  .ButtonKategoriKhusus {
    margin-top: 1rem;
    font-size: 1.25rem;
  }

  .scrolldown-indicator {
    color: white;
    font-size: 3rem;
    position: absolute;
    bottom: 2rem;
    cursor: pointer;
    animation: bounce 1s infinite;

    @keyframes bounce {
      0%,
      100% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
      }
      50% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
`
