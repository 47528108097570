import React from "react"

import Button from "../../Reusable/Button"
import { LandingCTAStyles } from "./style"

export default function LandingCTA() {
  return (
    <LandingCTAStyles>
      <div>
        <h3>Temukan Talentamu Sekarang</h3>
        <h4>
          <span className="gray">Daftar sekarang juga,</span> gratis!
        </h4>
        <div>
          <Button to="/register">Daftar</Button>
        </div>
      </div>
    </LandingCTAStyles>
  )
}
