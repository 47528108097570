import React from "react"
import Button from "../../Reusable/Button"
import { CgChevronDoubleDown } from "@react-icons/all-files/cg/CgChevronDoubleDown"
import { TopLandingStyles } from "./style"

export default function TopLanding() {
  return (
    <TopLandingStyles>
      <div className="video-container">
        <video autoPlay muted loop preload="auto">
          <source src="/content/background-landing.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="top-landing-wrapper">
        <div className="TopLandingContainer" id="home">
          <h1 className="JudulTalenta">TALENTA ITU PILIHAN</h1>
          <p className="SubJudulTop">
            Kembangkan talentamu bersama mentor favorit
          </p>
          <Button className="ButtonKategoriKhusus" to="/search">
            Jelajahi Kursus
          </Button>
        </div>
        <a href="#banners" className="scrolldown-indicator">
          <CgChevronDoubleDown />
        </a>
      </div>
    </TopLandingStyles>
  )
}
