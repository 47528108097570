import styled from "styled-components"

export const TutorCarouselStyles = styled.div`
  padding-bottom: 15px;
  padding-bottom: 6rem;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;

  h3 {
    font-weight: bold;
    padding: 10px 30px 0 30px;
  }

  .under-line {
    background: #ffce00;
    height: 0.5rem;
    width: 12rem;
    border-radius: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .subtitle {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 32rem;
    padding: 1rem;

    @media max-width(768px) {
      padding: 1rem 2rem;
    }
  }

  .tutor-list {
    border-radius: 16px;
    padding: 10px 15px;
    padding-top: 0;
    margin: 15px;

    .one-tutor {
      width: 100%;
      display: inline-block;
      padding: 33px;
      text-align: center;
      border-radius: 8px;

      .tutor-img {
        width: calc(100% + 20px);
        border-radius: 50%;
        position: relative;
        left: -10px;
        top: -10px;
      }

      .tutor-rating {
        margin-top: -15px;

        & > div {
          justify-content: center;
        }
      }
    }

    .one-tutor:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
        0 4px 6px -4px rgb(0 0 0 / 0.1);
    }
  }

  @media only screen and (max-width: 991.98px) {
    .tutor-list {
      padding: 10px 5px;

      .one-tutor {
        padding: 15px;
      }
    }
  }
`
