/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import Button from "../../Reusable/Button"
import Image from "../../image"
import CourseCard from "../../Reusable/CourseCard"
import { KursusCarouselStyles } from "./style"

import { getEditorsPicks } from "../../../services/course"

export default function KursusCarousel() {
  const [courses, setCourses] = useState([])

  useEffect(() => {
    getEditorsPicks().then(resp => {
      setCourses(resp.data.results)
    })
  }, [])

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: (
      <div>
        <Image imgName="right-chevron.png" alt="next" />
      </div>
    ),
    prevArrow: (
      <div>
        <Image imgName="left-chevron.png" alt="next" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <KursusCarouselStyles>
      <div className="kursus-judul">
        <h3>Ragam Kursus</h3>
      </div>
      <div className="under-line"></div>
      <p className="subtitle">
        Kuasai keterampilan masa kini bersama tutor mumpuni.
      </p>
      <div className="kursus-list">
        <Slider {...settings}>
          {courses?.map((course, index) => (
            <CourseCard course={course.course} key={index} />
          ))}
        </Slider>
      </div>
      <div className="kursus-see-more">
        <Button to="/search">Lihat lebih banyak</Button>
      </div>
    </KursusCarouselStyles>
  )
}
