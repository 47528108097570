import styled from "styled-components"

export const LandingCarouselStyles = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  .img-wrapper {
    transition: opacity 0.3s;
    display: block;
  }

  .img-wrapper:hover {
    opacity: 0.8;
  }

  .all-banners {
    display: flex;
    padding: 45px 0 40px 0;

    .img-wrapper {
      padding: 6px;
    }

    img {
      width: 100%;
    }
  }

  .banners-left {
    width: 67.4%;
  }

  .banners-right {
    width: 32.6%;
  }

  .mobile-only {
    display: none;
  }

  @media only screen and (max-width: 991.98px) {
    .all-banners {
      flex-wrap: wrap;
    }

    .banners-left {
      width: 100%;
    }

    .banners-right {
      width: 100%;
      display: flex;

      .img-wrapper {
        width: 50%;
      }
    }

    .desktop-only {
      display: none;
    }

    .mobile-only {
      display: block;
    }
  }
`
