import styled from "styled-components"

export const KursusCarouselStyles = styled.div`
  padding-bottom: 6rem;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  .kursus-judul {
    padding: 15px 35px 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    a {
      color: #f4b71a;
      font-weight: bold;
    }
  }

  .under-line {
    background: #ffce00;
    height: 0.5rem;
    width: 10rem;
    border-radius: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .subtitle {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 32rem;
    padding: 1rem;

    @media max-width(768px) {
      padding: 1rem 2rem;
    }
  }

  .kursus-list {
    padding: 10px 20px;
  }

  .kursus-see-more {
    display: flex;
    justify-content: center;
    padding-top: 2em;
    padding-bottom: 3em;
  }

  .slick-track {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: 767.98px) {
    .kursus-list {
      padding: 10px 4px;
    }
  }

  @media only screen and (max-width: 500px) {
    .kursus-judul a {
      display: none;
    }
  }
`
