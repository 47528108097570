/* eslint-disable camelcase */
import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Button from "../Button"
import Rating from "../Rating"
import { formatUang } from "../../../services/utils"
import { FiUsers } from "@react-icons/all-files/fi/FiUsers"

const CourseCardStyles = styled.div`
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  .one-kursus-wrapper {
    display: block;
    color: initial;
    transition: transform 0.2s;
  }

  .one-kursus-wrapper:hover {
    text-decoration: none;
  }

  .one-kursus {
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    position: relative;

    .kursus-background {
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding-bottom: 55%;
      border-radius: 4px 4px 0 0;
    }

    .kursus-desc {
      padding: 8px 12px;
    }

    h5 {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
    }

    .type {
      font-size: 14px;
      padding: 3px 10px;
      width: max-content;
      border-radius: 5px;
      margin-bottom: 10px;
      margin-top: 5px;
      font-weight: 600;
    }

    .typeBootcamp {
      background-color: #02ad53;
      color: white;
    }

    .typePS {
      background-color: black;
      color: #ffbf00;
    }

    .typeVC {
      background-color: #ffbf00;
      color: black;
    }

    .judul {
      height: 48px;
      overflow-y: hidden;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
    }

    .harga {
      margin-top: 0.5rem;
      font-size: 1rem;
      font-weight: 500;
    }

    small {
      display: block;
      color: rgb(30 41 59);
      padding-top: 4px;
      padding-bottom: 4px;
      height: 28px;
      overflow: hidden;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    .rating {
      padding: 0.5rem 0;
    }

    .discounted {
      text-decoration: line-through;
      color: grey;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    .discount-percentage {
      font-size: 0.75rem;
      line-height: 1rem;
      background: #9ca3af;
      border-radius: 4px;
      color: white;
      padding: 3px 3px 1px 4px;
    }
  }

  .one-kursus:hover {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  .button-wrapper {
    padding: 0 0.5rem 0.5rem 0.5rem;
    margin-top: -3rem;
    text-align: end;
  }

  .user-count {
    position: absolute;
    right: 1rem;
    bottom: 0.5rem;
    display: flex;
    gap: 0.25rem;
    font-size: 0.875rem;
    align-items: center;
  }

  .user-count > svg {
    margin-bottom: 0.25rem;
  }
`

export default function CourseCard({
  course,
  hidePrice,
  showReview,
  showEdit,
  showModal,
  ...props
}) {
  const { id, title, thumbnail, ratings, price, price_discounted } = course
  const mentor = course.tutor?.display_name || course.tutor?.username

  return (
    <CourseCardStyles {...props}>
      <div className="one-kursus">
        <Link to={`/course/${id}`} className="one-kursus-wrapper">
          <div
            className="kursus-background"
            style={{
              backgroundImage: `url(${thumbnail})`,
            }}
          />
          <div className="kursus-desc">
            {course?.type === "bootcamp" ? (
              <div className="type typeBootcamp ">Bootcamp</div>
            ) : course?.type === "private" ? (
              <div className="type typePS">Private Class</div>
            ) : (
              <div className="type typeVC">Video Course</div>
            )}
            <h5 className="judul">{title}</h5>
            <div>{mentor ? <small>{mentor}</small> : <></>}</div>
            <div className="rating">
              <Rating rate={ratings} />
            </div>

            {!hidePrice && (
              <h5 className="harga">
                {price_discounted
                  ? `Rp ${formatUang(price_discounted)}`
                  : `Rp ${formatUang(price)}`}
                {price_discounted && (
                  <>
                    &nbsp;
                    <span className="discounted">{`Rp${formatUang(
                      price
                    )}`}</span>
                    &nbsp;
                    <span className="discount-percentage">
                      -{(((price - price_discounted) / price) * 100).toFixed(0)}
                      %
                    </span>
                  </>
                )}
              </h5>
            )}
          </div>
        </Link>

        {showEdit && (
          <div className="button-wrapper">
            <Button onClick={showModal} className="small secondary">
              Edit ulasan
            </Button>
          </div>
        )}

        {showReview && !showEdit && (
          <div className="button-wrapper">
            <Button onClick={showModal} className="small secondary">
              Beri ulasan
            </Button>
          </div>
        )}

        {!showReview && (
          <div className="user-count">
            <FiUsers size="20" />
            {course?.jumlah_peserta}
          </div>
        )}
      </div>
    </CourseCardStyles>
  )
}
