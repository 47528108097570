import styled from "styled-components"

export const PartnerListStyles = styled.div`
  padding-bottom: 6rem;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  h3 {
    font-weight: bold;
    padding: 15px 30px 0 30px;
    text-align: center;
  }

  .under-line {
    background: #ffce00;
    height: 0.5rem;
    width: 12rem;
    border-radius: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .subtitle {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 32rem;
    padding: 1rem;

    @media max-width(768px) {
      padding: 1rem 2rem;
    }
  }

  .rekan-list {
    margin: 15px 0 40px 0;
    padding: 25px;
    background: #fffce8;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .one-rekan {
      margin: 0.75rem;
      padding: 1rem;
      width: 160px;
      text-align: center;
      border-radius: 8px;
    }

    .one-rekan-img {
      padding-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;

      div {
        border-radius: 999px;
      }
    }

    .one-rekan:hover {
      background-color: white;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
        0 2px 4px -2px rgb(0 0 0 / 0.1);
    }
  }

  @media only screen and (max-width: 767.98px) {
    .rekan-list {
      .one-rekan {
        margin: 10px 10px 10px 10px;
        width: 100px;
      }
    }
  }
`
