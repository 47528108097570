import React from "react"
import EventCardStyles from "./style"
import { BiCalendarEvent } from "@react-icons/all-files/bi/BiCalendarEvent"
import { BiMoney } from "@react-icons/all-files/bi/BiMoney"
import { Link } from "gatsby"

const EventCard = ({ event }) => {
  return (
    <Link
      to={`/events/${event.slug}`}
      style={{ textDecoration: "none", width: "100%" }}
      className="scale-background-on-hover"
    >
      <EventCardStyles>
        <div
          className="thumbnail"
          style={{
            backgroundImage: `url(${event.thumb})`,
          }}
        />
        <div className="body">
          <div className="category">{event.category.toUpperCase()}</div>
          <div className="title">{event.title}</div>
          <div className="tanggal">
            <BiCalendarEvent />
            <div>{event.date}</div>
          </div>
          {event.price && (
            <div className="harga">
              <BiMoney />
              <div>{event.price}</div>
            </div>
          )}

          <div className="detail">
            <div>Detail</div>
          </div>
        </div>
      </EventCardStyles>
    </Link>
  )
}

export default EventCard
