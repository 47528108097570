import styled from "styled-components"

const EventSectionStyles = styled.div`
  padding-bottom: 6rem;
  margin-left: 1rem;
  margin-right: 1rem;

  .judul {
    text-align: center;
  }

  .under-line {
    background: #ffce00;
    height: 0.5rem;
    width: 9rem;
    border-radius: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .subtitle {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 28rem;
    padding: 1rem;
    margin-bottom: 2rem;

    @media max-width(768px) {
      padding: 1rem 2rem;
    }
  }

  .main {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    justify-content: center;
    gap: 1rem;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  .card {
    margin: auto;
  }

  .event-list {
    padding-left: 8rem;
    padding-right: 8rem;
    height: max-content;
  }

  .slick-track {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .slick-slide {
    margin: 0 5px;
  }
  .slick-list {
    margin: 0 5px;
  }

  @media only screen and (max-width: 767.98px) {
    .event-list {
      padding: 10px 4px;
    }
  }
`

export default EventSectionStyles
