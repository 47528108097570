import React from "react"
import VideoPlayer from "../../Reusable/VideoPlayer"

import { WhySectionStyles } from "./style"

export default function WhySection() {
  return (
    <WhySectionStyles>
      <div className="why-left">
        <div className="why-left-block">
          <h2>Apa dan Mengapa Purwalenta bekerja untuk kamu?</h2>
          <p></p>
        </div>
      </div>
      <div className="why-right">
        <VideoPlayer source={"/content/why-video.webm"} />
      </div>
    </WhySectionStyles>
  )
}
