import React from "react"
import { connect } from "react-redux"
import TopLanding from "./TopLanding"
import LandingCarousel from "./LandingCarousel"
import TutorCarousel from "./TutorCarousel"
import WhySection from "./WhySection"
import KursusCarousel from "./KursusCarousel"
import PartnerList from "./PartnerList"
import LandingCTA from "./LandingCTA"

import * as sessionSelectors from "../../redux/slices/session/selectors"

import { LandingStyles } from "./style"
import EventSection from "./EventSection"

const mapStateToProps = state => ({
  isLoggedIn: sessionSelectors.isLoggedIn(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const LandingPage = props => {
  const { isLoggedIn } = props

  return (
    <LandingStyles>
      <TopLanding />
      <LandingCarousel />
      <KursusCarousel />
      <WhySection />
      <PartnerList />
      <EventSection />
      <TutorCarousel />
      {isLoggedIn ? "" : <LandingCTA />}
    </LandingStyles>
  )
}

export default withConnect(LandingPage)
